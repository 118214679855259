import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import AllUsers from "./pages/TotalCountries/AllUsers";
import Viewuser from "./pages/TotalCountries/Viewuser";
import TotalUser from "./pages/TotalCountries/TotalUser";
import TotalOperator from "./pages/TotalCountries/TotalOperator";
import SendNotification from "./pages/SendNotification";
import Chats from "./pages/Chats/Chats";
import Complaints from "./pages/Complaints/Complaints";
import NotificationsAlerts from "./pages/NotificationAlerts/NotificationsAlerts";
import Groups from "./pages/Groups/Groups";
import ChatBar from "./pages/ChatBar/ChatBar";
import GroupNotifications from "./pages/GroupNotifications/GroupNotifications";
import { Station } from "./pages/Station/Station";
import { SubKpis } from "./pages/SubKpis/SubKpis";
import { DailyReports } from "./pages/DailyReports/DailyReports";
import { Kpis } from "./pages/Kpis/Kpis";
import Offers from "./pages/Offers/Offers";
import Invoice from "./pages/Invoice/Invoice";
import TodayPrices from "./pages/TodayPrices/TodayPrices";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/all-users" />
        }
        <ContentRoute role={["admin"]} path="/dashboard" component={DashboardPage} />
        <ContentRoute role={["admin"]} path="/builder" component={BuilderPage} />
        <ContentRoute role={["admin"]} path="/my-page" component={MyPage} />
        <ContentRoute role={["admin"]} path="/all-users" component={AllUsers} />
        <ContentRoute role={["admin"]} path="/send-notification" component={SendNotification} />
        <ContentRoute role={["admin"]} path="/view-user/:userData" component={Viewuser} />
        <ContentRoute role={["admin"]} path="/chats/:groupid" component={Chats} />
        <ContentRoute role={["admin"]} path="/chatsbar" component={ChatBar} />
        <ContentRoute role={["admin"]} path="/complaints" component={Complaints} />
        <ContentRoute role={["admin"]} path="/notificationsalerts" component={NotificationsAlerts} />
        <ContentRoute role={["admin"]} path="/chat-groups" component={Groups} />
        <ContentRoute role={["admin"]} path="/group-notifications" component={GroupNotifications} />
        {/* <ContentRoute role={["admin"]} path="/station" component={Station} /> */}
        {/* <ContentRoute role={["admin"]} path="/kpis" component={Kpis} /> */}
        {/* <ContentRoute role={["admin"]} path="/subKpis/:id" component={SubKpis} /> */}
        {/* <ContentRoute role={["admin"]} path="/daily-report" component={DailyReports} /> */}
        {/* <ContentRoute role={["admin"]} path="/invoice" component={Invoice} /> */}
        <ContentRoute role={["admin"]} path="/offers" component={Offers} />
        <ContentRoute role={["admin"]} path="/today-prices" component={TodayPrices} />
        {/* <ContentRoute path="/total-user" component={TotalUser} />
        <ContentRoute path="/total-operator" component={TotalOperator} /> */}
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
