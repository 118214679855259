import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import { DollarOutlined } from "@ant-design/icons";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
function TodayPrices() {
  // Fields
  // const loginAccount=JSON.parse(localStorage.getItem("userDetails")).role[0])
  const history = useHistory();
  const params = useParams();
  console.log(params.id);
  const [loading, setloading] = useState(false);
  const [getLoading,setGetLoading]=useState(false)
  const [regular, setRegular] = useState();
  const [premium, setPremium] = useState();
  const [diesel, setDiesel] = useState();
  const [typeId, setTypeId] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [b20, setB20] = useState();
  const [rnew99, setRnew99] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [groups, setGroups] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  useEffect(() => {
    getGroups();
  }, []);
  const getGroups = () => {
    fetch(`${baseUrl}/admin/groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          let temparr = [];
          data.payload.forEach((group) => {
            temparr.push({
              label: group.name,
              value: group.id,
            });
          });

          setGroups(temparr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTypes = async (groupId) => {
    setGetLoading(true)
    await fetch(`${baseUrl}/admin/fuel-price-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTypes(data.payload);
        setTypeId(data.payload[0]?.id);
        getDefaultValues(data.payload[0]?.id, groupId);
        console.log(data.payload, "here we go");
      })
      .catch((err) => {
    setGetLoading(false)
        console.log("vehicle", err);
      });
  };

  // Methods
  const saveUser = () => {
    setloading(true);

    const myForm = new FormData();

    myForm.set("fuel_price_type_id", typeId);
    myForm.set("regular", regular);
    myForm.set("premium", premium);
    myForm.set("diesel", diesel);
    myForm.set("renew_99", rnew99);
    myForm.set("b_20", b20);
    myForm.set("date", currentDate);

    fetch(`${baseUrl}/admin/group/${selectedGroup}/fuel-prices`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: myForm,
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setloading(false);
          if (data.metadata) {
            if (data.metadata.success) {
              toast.success("Saved Successfully");
            } else if (!data.metadata.success) {
              if (toString.call(data.metadata.message) == "[object Object]") {
                const errorMessageArray = Object.values(
                  data.metadata.message
                ).flatMap((messages) => messages);
                errorMessageArray.forEach((message) => toast.error(message));
              } else if (
                toString.call(data.metadata.message) == "[object Array]"
              ) {
                Object.values(data.metadata.message).map((msg) => {
                  toast.error(msg);
                });
              } else {
                toast.error(data.metadata.message);
              }
              setloading(false);
            }
          }
        })
      )
      .catch((err) => {
        setloading(false);

        toast.error("SomeThing Went Wronge Please Try Again");
        console.log(err);
      });
  };

  const getDefaultValues = (id, groupId) => {
    fetch(`${baseUrl}/admin/group/${groupId}/fuel-prices/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setRegular(data?.payload?.regular || '');
        setPremium(data?.payload?.premium || '') ;
        setDiesel(data?.payload?.diesel || '');
        setB20(data?.payload?.b_20 || '');
        setRnew99(data?.payload?.renew_99 || '');
        setCurrentDate(data?.payload?.date || '');
        console.log(data.payload, "here we go");
    setGetLoading(false)

      })
      .catch((err) => {
    setGetLoading(false)
        console.log("vehicle", err);
      });
  };
  return (
    <div
      style={{ marginTop: "10px", paddingTop: 0 }}
      className="card card-custom"
    >
      <Toaster />
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div
          className="card-title align-items-start flex-column"
          style={{ paddingTop: "10px" }}
        >
          <h3 className="card-label font-weight-bolder text-dark">
            Today Prices
          </h3>
          {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span> */}
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            onClick={saveUser}
          >
            Save Prices
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              {/* <h5 className="font-weight-bold mb-6">Customer Info</h5> */}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Select Group
            </label>
            <div className="col-lg-9 col-xl-6">
              <Select
                onChange={(e) => {
                  setTypes([]);
                  setSelectedGroup(e.value);
                  getTypes(e.value);
                }}
                options={groups}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </div>
          {types.length > 0 ? (
            <>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Select Date
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="date"
                    className="form-control"
                    value={currentDate}
                    onChange={(e) => setCurrentDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Select Type
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    style={{ height: 40 }}
                    className="form-control"
                    aria-label="Default select example"
                    value={typeId}
                    onChange={(e) => {
                      getDefaultValues(e.target.value,selectedGroup);
                      setTypeId(e.target.value);
                    }}
                  >
                    {types &&
                      types.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Regular
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <DollarOutlined
                          style={{ top: "0px", fontSize: "1.5rem" }}
                        />
                      </span>
                    </div>
                    <input
                      step={0.0}
                      type="number"
                      placeholder="Enter Regular Price"
                      className={`form-control form-control-lg form-control-solid`}
                      value={regular}
                      onChange={(e) => setRegular(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Premium
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <DollarOutlined
                          style={{ top: "0px", fontSize: "1.5rem" }}
                        />
                      </span>
                    </div>
                    <input
                      step={0.0}
                      type="number"
                      placeholder="Enter Premium Price"
                      className={`form-control form-control-lg form-control-solid`}
                      value={premium}
                      onChange={(e) => setPremium(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Diesel
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <DollarOutlined
                          style={{ top: "0px", fontSize: "1.5rem" }}
                        />
                      </span>
                    </div>
                    <input
                      step={0.0}
                      type="number"
                      placeholder="Enter Diesel Price"
                      className={`form-control form-control-lg form-control-solid`}
                      value={diesel}
                      onChange={(e) => setDiesel(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Rnew99
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <DollarOutlined
                          style={{ top: "0px", fontSize: "1.5rem" }}
                        />
                      </span>
                    </div>
                    <input
                      step={0.0}
                      type="number"
                      placeholder="Enter Rnew99 Price"
                      className={`form-control form-control-lg form-control-solid`}
                      value={rnew99}
                      onChange={(e) => setRnew99(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">B20</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <DollarOutlined
                          style={{ top: "0px", fontSize: "1.5rem" }}
                        />
                      </span>
                    </div>
                    <input
                      step={0.0}
                      type="number"
                      placeholder="Enter B20 Price"
                      className={`form-control form-control-lg form-control-solid`}
                      value={b20}
                      onChange={(e) => setB20(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : 
          <div style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"50px"}}>
           {getLoading && <Spinner variant="dark" animation="border"/>}
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default TodayPrices;
