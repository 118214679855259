import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import './style.css'
// import Upload from "../../../app/Images/upload.png";
import { CloseOutlined } from "@ant-design/icons";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import toast from 'react-hot-toast';
import { baseUrl } from '../../../services/config';
const { Dragger } = Upload;

const Offers = () => {
    const [images,setImages]=useState([])
    const [apiFirstImage,setApiFirstImage]=useState("")
    const [apiSecondImage,setApiSecondImage]=useState("")
    const [apithirdImage,setApiThirdImage]=useState("")
    const [image1,setImage1]=useState()
    const [image2,setImage2]=useState()
    const [image3,setImage3]=useState()
    const [loading,setLoading]=useState(false)
    const [getLoading,setGetLoading]=useState(false)
    const authtoken = localStorage.getItem("userToken");
    
      useEffect(() => {
      getOffers()
  }, []);
  const getOffers = async () => {
    setGetLoading(true)
    await fetch(`${baseUrl}/admin/offers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.metadata){
          if(data.metadata.success){
            setApiFirstImage(data.payload[0]?.image?.original_url)
            setApiSecondImage(data.payload[1]?.image?.original_url)
            setApiThirdImage(data.payload[2]?.image?.original_url)
    setGetLoading(false)
          }
        }
        console.log(data.payload,"here we go");
      })
      .catch((err) => {
        console.log("", err);
      });
  };

    const handleInputChange = (event) => {
      const files = event.target.files;
  
      for (const file of files) {
        setImages((prevImages) => [...prevImages, file]);
      }
      console.log(images);
    };
    const handleImageRemove = (index) => {
      let data = document.getElementById("imagesupload").value
      console.log(data)
      console.log(index);
      images.splice(index, 1);
      setImages([...images]);
      const fileInput = document.getElementById("imagesupload");
      fileInput.value = "";
    };




    //////antd
    // const props = {
    //   name: 'file',
    //   accept:"image/png, image/gif, image/jpeg",
    //   multiple: true,
    //   action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    //   onChange(info) {
    //     const { status } = info.file;
    //     if (status !== 'uploading') {
    //       console.log(info.file, info.fileList);
    //       setPickerImages(info.fileList)
    //     }
    //     if (status === 'done') {
    //       message.success(`${info.file.name} file uploaded successfully.`);
    //     } else if (status === 'error') {
    //       message.success(`${info.file.name} file uploaded successfully.`);
    //     }
    //   },
    //   onDrop(e) {
    //     console.log('Dropped files', e.dataTransfer.files);
    //   },
    // };


    const uploadFilesToApi=()=>{
 
        const fdata = new FormData();
        if(image1){
          fdata.append("first_image", image1);
        }
        if(image2){
          fdata.append("second_image", image2);
        }
        if(image3){
          fdata.append("third_image", image3);
        }
        setLoading(true);
    
        fetch(`${baseUrl}/admin/offers`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          body: fdata,
        }).then((resp) => {
          resp.json().then((data) => {
            if (data.metadata) {
              if (data.metadata.success) {
                setLoading(false);
                toast.success('Saved Successfully')
              }else{
                setLoading(false);
              }
            }
          });
        });
    }
  return (
    <>
     {loading || getLoading ? <ModalProgressBar />:''}
          <Card>
        <>
          <CardHeader title="Offers">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={uploadFilesToApi}
              disabled={loading}
            >
              Save Uploads
            </button>
          </CardHeaderToolbar>
          </CardHeader>
 
          <CardBody>


          {/* <Dragger height={350} {...props} maxCount={3}>
    <p className="ant-upload-drag-icon">
      <UploadOutlined style={{color:"black",fontSize:"5rem"}}/>
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
    Please note that admin can upload a maximum of three images or a minimum of three images.
    </p>
  </Dragger> */}

{
  getLoading ?
  '' :

<div className='row'>
              <div className='col-md-4'>
               <div className='iamgeBox'>
               <div className='cardHeaderImage'>
                     <h6>First Image</h6>
                     <label htmlFor='imagesupload1' className='btn btn-primary'>
                     Upload
                     </label>
                 </div>
                 <div className='imageContainerUpload'>
                    {image1?   <img src={URL.createObjectURL(image1)} alt='image1'/>:apiFirstImage ? 
                    <img src={apiFirstImage} alt='image1'/>
                    :
                    ''
                    }
                 </div>
               </div>
              </div>
              <div className='col-md-4'>
               <div className='iamgeBox'>
               <div className='cardHeaderImage'>
                     <h6>Second Image</h6>
                     <label htmlFor='imagesupload2' className='btn btn-primary'>
                     Upload
                     </label>
                 </div>
                 <div className='imageContainerUpload'>
                    {image2?   <img src={URL.createObjectURL(image2)} alt='image2'/>:apiSecondImage ? 
                    <img src={apiSecondImage} alt='image1'/>
                    :
                    ''}
                 </div>
               </div>
              </div>
              <div className='col-md-4'>
               <div className='iamgeBox'>
               <div className='cardHeaderImage'>
                     <h6>Third Image</h6>
                     <label htmlFor='imagesupload3' className='btn btn-primary'>
                     Upload
                     </label>
                 </div>
                 <div className='imageContainerUpload'>
                    {image3?   <img src={URL.createObjectURL(image3)} alt='image3'/>:apithirdImage ? 
                    <img src={apithirdImage} alt='image1'/>
                    :
                    ''}
                 </div>
               </div>
              </div>
            </div>
}





          {/* <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="avatar-upload-input text-center">
                <img src={Upload} alt="img" />
                <h2>Upload File</h2>
                <div className="avatar-edit-input">
                  <input
                    className="form-control"
                    name="file"
                    id="imagesupload"
                    multiple
                    type={"file"}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div> */}
                 {/* <div className="d-flex mt-5">
            {images.map((item, index) => (
              <div className="" style={{ position: "relative" }}>
                <CloseOutlined
                  onClick={() => handleImageRemove(index)}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    color: "red",
                    fontSize:"20px",
                    right: "-10px",
                    cursor: "pointer",
                  }}
                />
                <img
                  style={{ height: "100px", width: "100px",marginLeft:"15px" }}
                  src={URL.createObjectURL(item)}
                />
              </div>
            ))}
          </div> */}
           <input
           style={{ display: "none" }}
           className="btn btn-base"
           type="file"
           id="imagesupload1"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      setImage1(e.target.files[0]);
                    }}
                  />
                   <input
                   style={{display:"none"}}
                    className="form-control"
                    name="file"
                    id="imagesupload2"
                    type={"file"}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      setImage2(e.target.files[0]);
                    }}
                  />
                   <input
                   style={{display:"none"}}
                    className="form-control"
                    name="file"
                    id="imagesupload3"
                    type={"file"}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => {
                      setImage3(e.target.files[0]);
                    }}
                  />
          </CardBody>
          </>
      </Card>
    </>
  )
}

export default Offers