import React, { useEffect } from "react";
// import "./notifations.css";
import { Avatar } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import firebase from "firebase";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import usePagination2 from "../../Components/usePagination2";
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const GroupNotifications = ({ item }) => {
  const classes = useStyles();
  const [notificationList, setnotificationList] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [deleteModel, setdeleteModel] = useState(false);
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(notificationList.length / PER_PAGE);
  const _DATA = usePagination2(notificationList, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
 
  useEffect(() => {
    // getNotifications(currentPage);
    getAllNotifications()
  }, []);

  const getAllNotifications=()=>{
    setloading(true)
    firebase
    .database()
    .ref('notificationsAlert')
    .on('value', (snapshot) => {
      console.log("aya",snapshot)
      setloading(false)
      let arr = [];
      if (snapshot.exists()) {
        snapshot.forEach((item,i) => {
          let value = item.val();
          value.id = item.key;
          arr.push(value);
        });
        arr.map((item,i)=>{
         item.sr=i+1
        })
        setnotificationList(arr);
        // console.log(arr,"all notifications")
        // if(arr.length){
        //   for (let i = 1; i < arr.length/10; i++) {
        //     transform.push(
        //       {active:i==1?true:false,
        //       label:i}
        //     )
        //   }
        //   setlinks(transform)
        // console.log(transform,"this is my transform arry")
        // }
        setloading(false)
      } else {
      }
    });
  }
 
  const deleteThisNotifaction=()=>{
    firebase
    .database()
    .ref('notificationsAlert')
    .child(selectedId)
    .remove()
    .then(() => {
      setdeleteModel(false);
      getAllNotifications()
    });

  }

  return (
    <>
        <Card>
        <>
          <CardHeader title="Notifications History">
        
          </CardHeader>
 
          <CardBody>
          <Table responsive>
          <thead style={{ marginTop: "20px" }}>
            <tr id="tbl">
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Sr.no
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Title
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Username
              </th>

              <th
                style={{
                  textAlign: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70vh",
                  width: "70vw",
                  position: "absolute",
                }}
              >
                <span className="spinner-border text-danger text-center"></span>
              </div>
            ) : (
              _DATA.currentData().map((item, index) => (
                <tr
                  className="colorChange"
                  id="tbl"
                  style={{ textAlign: "center" }}
                  key={item.id}
                >
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.userName}</td>
                  <td>
                    <span
                    title="delete"
                      onClick={() => {
                        setSelectedId(item.notificationId)
                        setdeleteModel(true)}}
                      style={{ cursor: "pointer" }}
                      className="svg-icon svg-icon-md svg-icon-danger ml-4"
                    >
                      <SVG
                        title="delete"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                     
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {
              !loading ?
              <div className={classes.root}>
              <Pagination color="primary" page={page} count={count} variant="outlined" shape="rounded"
                  onChange={handleChange}
              
              />
            </div>:''
            }
          </CardBody>
        </>
      </Card>
      <Modal
            centered
            show={deleteModel}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to Delete?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={()=>setdeleteModel(false)}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={deleteThisNotifaction}
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
            
        </Modal>
    </>
  );
};

export default GroupNotifications;
