
import firebase from "firebase/app";
import "firebase/database";


export const push_key = 'BMXWtT5_b-y2kZwmBN_fM1Mip11tH6o6dRJ_5304ZpHfIvuJApU5UE-8yBHr8MBl77RujQ6MJqyxP75qvBnyqAw'
// export const push_key = 'BHrvqgclKNmluNyuzrjI8V2laWKI5jD_QzSdyNkZWXT2t__oJJdP4oeKFlSNpeBJ0-CjPjmTJjluz-zSaZudKhE'

export const firebaseConfig = {
  apiKey: "AIzaSyC3Xm-cUZEzlybyBMXzzDx7AdVJieIzaF0",
  authDomain: "milestone-energy.firebaseapp.com",
  projectId: "milestone-energy",
  storageBucket: "milestone-energy.appspot.com",
  messagingSenderId: "197151984451",
  appId: "1:197151984451:web:3586fd3acf97174642bdf6",
  measurementId: "G-YRGTCDVSGG"
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}





export const getTokenfirebase = (setTokenFound) => {
  return firebase.messaging().getToken({ vapidKey: push_key }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}



export const onMessageListener = () =>
  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
  });


  export const firebaseAuth = firebase.auth();

  export const googleAuthProvider  = new firebase.auth.GoogleAuthProvider()
  export const database = firebase.database();