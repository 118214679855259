import React, { useState, useEffect } from "react";
import { Button, Modal, Form, ListGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { NavLink, useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";
import Pagination from "../../../_metronic/layout/components/Pagination/pagination";
import DeleteModal from "../../Components/DeleteModal";
import firebase from "firebase";
import { database } from "../../../firebase/config";
import { Image } from "antd";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls";
import Upload from "../../../app/Images/upload.png";


function Invoice() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const authtoken = localStorage.getItem("userToken");
  const [UsersList, setUsersList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [image,setImage]=useState('')

  useEffect(() => {
    getAllUser();
  }, []);

  // let a = "ahmad"
  // let  b = "this is my name"+a
  // let c = `this is my name ${a}`

  const getAllUser = async () => {
    setloading(true);
    await fetch(`${baseUrl}/admin/user`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
        // 'X-Requested-With': 'XMLHttpRequest'
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false);
          setUsersList(data.payload.reverse());
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };


  // console.log(item)
 console.log(UsersList)
  // const edititem = (item) => {
  //   handleShow()
  //   setCountryName(item.name);

  // };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Add Users
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Upload Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="avatar-upload-input text-center">
                <img src={Upload} alt="img" />
                <h2>Upload File</h2>
                <div className="avatar-edit-input">
                  <input
                    className="form-control"
                    name="file"
                    id="imagesupload"
                    type={"file"}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Upload</Button>
        </Modal.Footer>
      </Modal>
      <Card>
        <>
          <CardHeader title="Invoice">
            <CardHeaderToolbar>
         
            </CardHeaderToolbar>
          </CardHeader>
 
          <CardBody>
          <ListGroup className="mt-5">
        <Table responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Name</th>
              <th>Email</th>
              <th>View</th>
              <th>Upload Invoice</th>
              {/* <th>Edit</th>
              <th>Delete</th> */}
            </tr>
          </thead>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                position: "absolute",
              }}
            >
              <span className="spinner-border text-danger text-center"></span>
            </div>
          ) : (
            UsersList.map((item, index) => (
              <tbody key={item.id}>
                <tr className="colorChange">
                  <td>{item.id}</td>
                  <td>
                    {item.image?
                     <Image style={{borderRadius:"100%"}} height={35} width={35} className="chatimg" src={item.image.original_url} />
                    :"N/A"} 
                  </td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <span
                    title="view"
                      onClick={() => {
                        history.push(`/view-user/${item.id}`, {
                          userData: item,
                        });
                      }}
                      className="ml-1 svg-icon svg-icon-md svg-icon-primary"
                      style={{ cursor: "pointer" }}
                    >
                      <SVG
                      title="view"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Devices/Display2.svg"
                        )}
                      />
                    </span>
                  </td>
                  {/* <td>
                  <span  className="ml-1 svg-icon svg-icon-md svg-icon-primary" 
                  onClick={()=> edititem(item)}>
                     <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                  </span>
                </td> */}
                  <td>
                   <button onClick={handleShow} className="btn btn-primary">Upload</button>
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </Table>
      </ListGroup>
          </CardBody>
          </>
      </Card>

   
    </>
  );
}

export default Invoice;
